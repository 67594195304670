header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 1.5em;
  justify-content: space-between;
  z-index: 10;
  transform: translateY(-100%);
  background-color: transparent;
  transition: 0.6s ease-in-out transform, 0.6s ease-in-out background-color;
  &.visible {
    transform: translateY(0%);
  }
  &.showAbout {
    background-color: black;
  }

  img {
    height: 2.5em;
  }
  .buttons {
    display: flex;
    align-items: center;
    .button:hover {
      transition: opacity 200ms ease-in;
      opacity: 0.66;
    }
    .button {
      margin-left: 1em;
      cursor: pointer;

      &.about {
        padding: 0.25em 0.75em;
        text-decoration: underline;
      }
      &.about:hover {
        text-decoration: none;
      }
      &.fullscreen {
        display: none;
        @media (hover: hover) {
          display: block;
        }
        margin-left: 20px;
        background: url("../images/openFullscreen.svg") 0 0 no-repeat;
        width: 32px;
        height: 21px;
        background-size: 100%;

        &.isFullscreen {
          background-image: url("../images/closeFullscreen.svg");
        }
      }
    }
  }
}
