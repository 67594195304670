@import '../colors.scss';


html.snap {
  @media (hover: hover) {
    scroll-snap-type: y mandatory;

    @media not all and (min-resolution: .001dpcm) { // detect Safari
      scroll-snap-type: unset;
    }
  }
}


.ThreeScene {
  position: relative;
  width: calc(100vw - 20px);
  // height: 100vh; //
  font-size: 16px;

  @media only screen and (max-width: 380px) {
    font-size: 6px;
  }
  @media only screen and (max-width: 800px) {
    font-size: 10px;
  }
  // height: 62.5vw;
  // max-height: 81.25vh;
  background: #0b0b0b;

  .opacityTransition {
    transition: 0.3s ease-in-out opacity;
  }

  .canvas {
    position: fixed !important;
    top: 0;
    left: 0;
  }

  .tooltip {
    position: absolute;
    // top: 18.75vh;
    top: 0;
    // margin-top: 1em;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 45vw;
    // max-width: 50vw;
    background: rgba(255, 255, 255, 0.75);
    font-size: 0.75em;
    color: black;

    .row {
      display: flex;

      &.bold {
        font-weight: bold;
      }

      .cell {
        flex: 2;
        padding: 0.25em;
        border: 1px solid white;
        box-sizing: border-box;

        &.key {
          flex: 1;
        }
      }
    }
  }

  .timelineScroll {
    // position: fixed;
    // top: 0;
    pointer-events: none;
    // scroll-snap-type: y mandatory;
    // overflow: scroll;
    // max-height: 100vh; //
    .pageScroll {
      position: relative;
      // scroll-snap-align: start;
      // background-color: rgba(0, 0, 255, 0.3);
      // border: 1px solid red;
      // width: calc(100vw - 20px);
      // margin: 0 auto;
      width: calc(100vw - 2em);
      margin: 0 1em;
      // height: 100vh; //
      scroll-snap-align: start;

      > div {
        height: 100%;
      }


      .incidentTypeGrid {
        pointer-events: none;
        opacity: 0;
        transition: 0.3s ease-in-out opacity;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;

        > div {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.25);
          font-size: 2em;
        }

      }

      &.active {
        .incidentTypeGrid {
          opacity: 1;
        }
      }
    }
  }


  .fixedPositionContent {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .fixedLabel {
      position: absolute;
      right: 15vw;
      top: 50%;
      margin-top: -4em;
      width: 30vw;

      .copy {
        position: absolute;
        right: 0;
        opacity: 0;
        transform: translateX(50%);
        animation: none;
        transition: opacity 240ms linear, transform 240ms linear;

        &.red {
          color: #ff3c14;
          opacity: 0;
          animation: exitRedFixed 240ms forwards;
        }

        span {
          font-size: 1.5em;
        }
      }

      &.visible {
        .copy {
          opacity: 1;
          transform: translateX(50%) translateY(0);
          animation: transitionWhiteFixed 2s forwards;

          &.red {
            opacity: 0;
            animation: transitionRedFixed 960ms forwards 2s;
          }
        }
      }
    }
  }

  @keyframes transitionWhiteFixed {
    0% {
      opacity: 0;
      transform: translateX(50%) translateY(80%);
    }
    20% {
      opacity: 1;
      transform: translateX(50%) translateY(0%);
    }
    80% {
      opacity: 1;
      transform: translateX(50%) translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(50%) translateY(-80%);
    }
  }

  @keyframes transitionRedFixed {
    0% {
      opacity: 0;
      transform: translateX(50%) translateY(80%);
    }
    25% {
      opacity: 1;
      transform: translateX(50%) translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(50%) translateY(0%);
    }
  }

  @keyframes exitRedFixed {
    0% {
      opacity: 1;
      transform: translateX(50%) translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(50%) translateY(-80%);
    }
  }


  .fullscreenCopy {
    transition: 0.6s ease-in-out opacity, 0.6s ease-in-out transform;
    opacity: 0;
    // text-align: justify;
    text-align: center;
    // font-size: 3em;
    font-size: 2.25em;
    // transform: translateX(-50%) translateY(-20%);
    // font-style: italic;
    // font-style: normal !important;
    // color: red;
    font-family: BeausiteClassicWebMediumItalic;

    &.paragraph {
      width: 27em;
      font-size: 2em;
      max-width: none;
      margin: 0 auto 1.5em;
    }

    &.fullscreenCopyBottom {

      position: absolute;
      left: 50%;

      bottom: 2.5em;
      transform: translateX(-50%) translateY(30%);

      font-size: 1.5em;

      span {
        font-size: 0.75em;
      }
    }
  }

  .paragraphContainer {
    transform: translateY(-50%);
  }

  .fullscreenCopy {
    &.visible {
      opacity: 1;
      // transform: translateX(-50%) translateY(-50%);
    }

    // &.out {
    //   opacity: 0;
    //   transform: translateX(-50%) translateY(-80%);
    // }


    &.fullscreenCopyBottom {

      &.visible {
        transform: translateX(-50%) translateY(0%);
      }

      &.out {
        transform: translateX(-50%) translateY(-30%);
      }
    }

    &.showScrollArrow {
      position: fixed;
      bottom: 2.75em;
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      pointer-events: none;;

      &.background {
        span, .arrow {
          padding: 0.25em 0.25em;
          background-color: rgba(11, 11, 11, 0.7);
        }
      }

      &.showScrollToContinue {
        pointer-events: all;
        opacity: 1;
      }
    }

    &.showScrollArrow .arrow {
      position: absolute;
      cursor: pointer;
      top: 1.55em;
      left: 0;
      right: 0;
      height: 1.5em;
      width: 1em;
      margin: 0 auto;
      text-align: center;
      content: ' ';
      // background-color: #cde;
      background-image: url('../images/down-chevron.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 0.75em;
    }

  }


  .blockBackground {
    // bottom: 0.75em;
    // height: 3.45em;
    padding: 0.5em 1em;
    background: rgba(11, 11, 11, 0.7);
    font-family: 'BeausiteClassicWebMediumItalic';
    margin-left: 55%;
    font-size: 1.5em;
    max-width: 20em;
    transform: translateY(-150%);
  }

  .timelineUI {
    opacity: 0;
    transition: 0.3s ease-in-out opacity;
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 1em;

    select {
      width: 9em;
      font-size: inherit;
      margin-top: 0.5em;
      color: #fff;
      appearance: none;
      pointer-events: none;
      border: 1px solid #fff;
      padding: 0.5em 1.5em 0.5em 0.5em;
      background: #000 url('../images/downArrow.svg') center right 0.5em no-repeat;
      outline: none;
    }

    .timelineAttributes {
      margin-top: 0.5em;
      margin-left: 0em;

      .attribute {
        margin-left: calc(0.5em + 3px);
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;

        > div {
          display: inline-block;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          margin-right: 0.5em;
        }
      }
    }


    &.narrow {
      top: 50px;

      .timelineSelect {
        margin-right: 1em;


        br {
        }

        select {
          font-size: 1.5em;
          width: 100%;
        }

      }

      .attribute {
        font-size: 1.25em;
        display: inline-flex;
      }
    }

  }

  .active {
    .timelineUI {
      opacity: 1;

      select {
        pointer-events: all;
      }
    }
  }

}


@media (hover: none) {
  html, body {
    position: fixed;
  }
  .ThreeScene {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
  }


  html.snap {
    .ThreeScene {
      scroll-snap-type: y mandatory;
    }
  }
}


@media only screen and (max-width: 800px) {
  .fullscreenCopyBottom.showScrollToContinue.showScrollArrow span {
    font-size: 16px;
  }
}
