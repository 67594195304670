@import '../colors.scss';

.intro {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .logo {
    transition: 0.3s ease-in-out opacity, 0.3s ease-in-out transform;
    // width: 14em;
    // height: 14em * 0.46;
    height: 10em;
    margin: 0 auto;
  }
  .presents {
    display: none;
    font-size: 16px;
    text-transform: uppercase;
    transition: 0.3s ease-in-out opacity;
    transition-delay: 300ms;
  }

}

.disclaimer {
  color: white;
  max-width: 25em;
  font-size: 16px;
  text-align: center;
  top: 70%;
  position: absolute;
  opacity: 0;
  transition: 0.5s ease-in-out opacity;

  h4 {
    text-transform: uppercase;
    margin: 0.5em 0;
    font-size: 16px;
  }

  .text {
    padding: 0 1.25em;
    line-height: 1.4em;
    margin-bottom: 1em;
  }

  .continue {
    display: inline-block;
    width: 16em;
    height: 32px;
    margin-top: 1em;
    padding: 0 2em 0 0;
    color: #fff;
    text-transform: uppercase;
    background-color: #e62700;
    background-image: url('../images/arrow_cta.png');
    background-position: 73% 48%;
    background-size: 14px;
    background-repeat: no-repeat;
    font-size: 14px;
    letter-spacing: .1em;
    line-height: 32px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.1s linear all;
    &:hover {
      //border: 1px solid #1ed7fc;
      background-color: #000;
      filter: invert(100%);
    }
  }
}

.replayButton {
  left: 50%;
  transform: translateX(-50%);
  &.showReplayButton {
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (max-width: 640px) {
  .disclaimer {
    top: 65%;
  }
}
