
.footer {
  background-color: #0b0b0b;
  .callToAction {
    max-width: 30em;
    font-size: 16px;
    margin: 0 auto;
    text-align: center;
  }

  pointer-events: none;
  opacity: 0;
  transition: 0.3s ease-in-out opacity;
  transition-delay: 2500ms;

  .logo {
    padding-top: 16px;
    max-width: 128px;
  }

  .footer-cta {
    margin-bottom: 64px;
    line-height: 1.3;
  }

  a {
    color: #fff;
  }

  .footer-bottom {
    display: flex;
    font-size: 0.9em;
    margin: 0 auto;
    justify-content: space-between;
    padding: 24px 18px 24px 24px;

    a {
      text-decoration: none;
      padding-bottom: 0.2em;

      &.redHover:hover {
        color: #e62700;
      }

      &.redUnderline {
        border-bottom: 0.07em solid #e62700;
      }
    }

    > div {
      display: flex;
      &.left {
        a {
          margin-right: 0.4em;
        }
      }
      &.right {
        a {
          margin-left: 1em;
        }
      }
    }

    @media screen and (max-width: 640px) {
      flex-direction: column;
      font-size: 1.1em;
      padding: 5px 5px 5em 5px;
      .hideOnNarrow {
        display: none;
      }
      .left {
        display: block;
        > a:first-child {
          display: block;
        }
        .pitch {
          display: inline-block;
        }
      }
      .right {
        margin-top: 1em;
        > div:first-child {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.pageScroll.active {
  .footer {
    opacity: 1;
    pointer-events: all;
  }
}

