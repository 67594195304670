@import "../../colors.scss";

.share {
  display: flex;
  justify-content: center;
  .service {
    width: 32px;
    height: 32px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .service:not(:last-child) {
    margin-right: 20px;
  }
  .service:hover {
    transition: opacity 200ms ease-in;
    opacity: 0.66;
  }
}
