* {
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
}

body.noScroll {
  @media (hover: hover) {
    overflow: hidden;
  }
  .ThreeScene {
    touch-action: none;
  }
  touch-action: none;
}
body.scroll {
  overflow: visible;
  overflow-x: hidden;
  touch-action: pan-x pan-y;
  .ThreeScene {
    touch-action: pan-x pan-y;
  }
}

@font-face {
  font-family: BeausiteClassicWeb;
  src: url('./fonts/BeausiteClassicWeb-Clear.woff') format('woff');
  font-weight: normal;
  font-style: normal, clear;
}

@font-face {
  font-family: BeausiteClassicWebMediumItalic;
  src: url('./fonts/BeausiteClassicWeb-MediumItalic.woff') format('woff');
  font-weight: medium;
  font-style: italic, oblique;
}

@font-face {
  font-family: BeausiteClassicWebBold;
  src: url('./fonts/BeausiteClassicWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal, clear;
}

@font-face {
  font-family: DamienDisplay;
  src: url('./fonts/DamienDisplay_Medium.woff2') format('woff2'),
    url('./fonts/DamienDisplay_Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal, clear;
}

body {
  touch-action: pan-x pan-y;
}

.App {
  display: flex;
  background: #3b3b3b;
  color: white;
  font-family: BeausiteClassicWeb;

  .ThreeScene {
    flex: auto;
  }

  .top {
    height: 18.75vh
  }

  .legend {
    display: flex;
    flex-wrap: wrap;

    .type {
      display: flex;
      white-space: nowrap;
      padding: 0.5em;

      .block {
        width: 1em;
        height: 1em;
        margin-right: 0.25em;
      }

      span {
        font-size: 0.75em;
      }
    }
  }

  .light {
    background: white;
    color: black;
  }
}
