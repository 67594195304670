.Minimap {
  position: absolute;
  right: 1em;
  top: 0;
  pointer-events: none;
  cursor: pointer;
}
.active .Minimap {
  pointer-events: all;
}

@media screen and (max-width: 640px) {
  .active .Minimap {
    opacity: 0;
    pointer-events: none;
  }
}